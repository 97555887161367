import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  Grid
} from "@material-ui/core";
import hireintro from "static/images/hire/hireintro.jpeg";

const useStyles = makeStyles(theme => ({
  card: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  content: {
    textAlign: "center"
  },
  cover: {
    minWidth: 600,
    height: 360
  }
}));

export default function SectionIntro() {
  const classes = useStyles();
  return (
    <div
      style={{
        marginTop: "5%"
      }}
    >
      <Card className={classes.card}>
        <Grid container>
          <Grid item sm={12} xs={12} md={6}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography component="p" variant="h4">
                  Four Days Regalia Hire
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  Colour City Studio has been supplying academic regalia for
                  hire in the Auckland region for 23 years. We offer academic
                  gowns and hoods for almost every degree, and we are one of
                  only three official suppliers in Auckland. Your graduation
                  gown and regalia can be purchased or hired through us, best
                  price guarantee. ​
                </Typography>
                <br />
                <Divider />
                <br />
                <Typography variant="body1" color="textSecondary">
                  In order to make sure you get the best services, just simply
                  give us a call on 09-3032223.
                </Typography>
              </CardContent>
            </div>
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            {" "}
            <CardMedia
              className={classes.cover}
              style={{ minWidth: 375 }}
              image={hireintro}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
