import * as TYPES from '../action-type';
import { removeCart, addCart, clearCart } from '../../utils/shoppingCart';

const orderlist = {
  add_to_cart(item) {
    const data = addCart(item);
    return dispatch => {
      dispatch({
        type: TYPES.ADD_TO_CART,
        data
      });
    };
  },
  remove_from_cart(index) {
    const data = removeCart(index);
    return dispatch => {
      dispatch({
        type: TYPES.REMOVE_FROM_CART,
        data
      });
    };
  },
  clear_cart() {
    const data = clearCart();
    return dispatch => {
      dispatch({
        type: TYPES.CLEAR_CART,
        data
      });
    };
  }
};

export default orderlist;
