import React from 'react'
import {Divider, Typography} from '@material-ui/core'

export default function SectionNote() {
    return (
        <div>
            <Divider/><br/>
            <Typography variant='body2' color='textSecondary'>
                Please note: GD / PGD will have extra $20 for Scarf while Bachelor and Master don't have.
            </Typography>
        </div>
    )
}
