import React from 'react';
// import {makeStyles} from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SectionOrderInfo from './Sections/SectionOrderInfo';
import SectionPayment from './Sections/SectionPayment';
import background from 'static/images/other/paymentbg.jpg';

function OrderPage(props) {
  const orderInfo = props.location.state;

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: '100% 100%',
        padding: '6% 8%'
      }}
    >
      <Container
        style={{
          backgroundColor: 'white',
          minHeight: '50vh',
          borderRadius: '2vh'
        }}
      >
        {orderInfo && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <SectionOrderInfo orderInfo={orderInfo} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SectionPayment orderInfo={orderInfo} />
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default OrderPage;
