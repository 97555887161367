import config from '../config'

const baseURL = config.baseURL;
const axios = require('axios').create({
  baseURL: baseURL,            //api请求的baseURL
  timeout: 10000,
  withCredentials: true, // 允许跨域 cookie
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  maxContentLength: 2000,
  transformResponse: [function (data) {
    try {
      data = JSON.parse(data);
    } catch (e) {
      data = { data: {} };
    }
    return data;
  }]
});

// get
export const _get = (req) => {
  return axios.get(req.url, { params: req.data })
};

// post
export const _post = (req) => {
  return axios({ method: 'post', url: `/${req.url}`, data: req.data })
};


