import React from 'react'

import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons

import Build from "@material-ui/icons/Build";
// core components
import GridContainer from "components/layout/Grid/GridContainer.js";
import GridItem from "components/layout/Grid/GridItem.js";
import CustomTabs from "components/layout/CustomTabs/CustomTabs.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionTerms() {
    const classes = useStyles();
    return (

        <div className={classes.container}>
            <div id="nav-tabs">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomTabs
                            headerColor="info"
                            tabs={[{
                                tabName: "Terms & Conditions",
                                tabIcon: Build,
                                tabContent: (
                                    <div>
                                        <h2>GRADUATION REGALIA HIRE</h2>
                                        <p className={classes.textCenter}>Colour City Studio has been supplying academic
                                            regalia for hire in the Auckland region for 23 years. We offer academic gowns
                                            and hoods for almost every degree, and we are one of only three official
                                            suppliers in Auckland. Your graduation gown and regalia can be purchased or
                                            hired through us, best price guarantee.
                                        </p>
                                        <p className={classes.textCenter}>In order to make sure you get the best
                                            services, please check your regalia availability first or just simply give us a
                                            call.</p>
                                        <p className={classes.textCenter}>
                                            <strong>Please Note:
                                            </strong>
                                        </p>
                                        <p className={classes.textCenter}>We provide 4 days for hire and all items must
                                            be returned on time.The rental fee and deposit need to be paid in full before
                                            the above items are released for rental. If the items are not returned after due
                                            days, $20 will be deducted from the deposit per day per item. Customers need to
                                            inspect them for confirmation. If any rental item is lost or returned in damaged
                                            conditions, customer needs to pay for the item. Colour City Studio has the final
                                            rights in the interpretation of the above conditions.</p>
                                        <p className={classes.textCenter}>Cost for regalia as following​ Gown:
                                            <br/>
                                            $280 Hood $95 Trencher $60</p>
                                        <p className={classes.textCenter}>
                                            <strong>
                                                Refund Policy:
                                            </strong>
                                        </p>
                                        <p className={classes.textCenter}>When you purchase for regalia hire we charge
                                            NZ$100 as bond. The NZ$100 bond will be transferred back to your bank account
                                            within 7 working days when you returned items undamaged, we will charge extra
                                            from NZ$30 to NZ$100 depends on the extent of damage. NZ$20 cancellation fee
                                            applied for each booking. (Special booking deposit is none refundable)</p>
                                    </div>
                                )
                            }
                        ]}/>
                    </GridItem>
                </GridContainer>
            </div>
        </div>

    )
}
