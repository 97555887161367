
import React from 'react';
import { Container } from '@material-ui/core';
import SectionIntro from './Sections/SectionIntro';
import SectionSelect from './Sections/SectionSelect';
import SectionTerms from './Sections/SectionTerms';
import SectionNote from './Sections/SectionNote';
import regaliabg from 'static/images/hire/regaliabg.png';

export default function Graduation() {
  return (
    <div>
      <Container
        style={{
          textAlign: 'center'
        }}
      >
        <SectionIntro />
        <SectionSelect />
        <SectionNote />
      </Container>
      <Container
        style={{
          textAlign: 'center'
        }}
      >
        <SectionTerms />
      </Container>
      <img
        src={regaliabg}
        alt=''
        style={{ backgroundPosition: 'center', width: '100%' }}
      />
    </div>
  );
}
