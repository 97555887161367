import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import album from 'static//images/product/album.png';
import bouquet from 'static//images/product/bouquet.png';
import frame from 'static//images/product/frame.png';
import wreath from 'static//images/product/wreath.png';

export default function Banner() {
  const styles = {
    marginTop: '1.5%',
    color: 'rgba(91,91,87,1)'
  };

  return (
    <Grid
      container
      style={{
        margin: '4% auto',
        justifyContent: 'center',
        padding: '0 20%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Grid item xs={6} sm={3}>
        <img
          src={album}
          alt=''
          style={{
            height: '8vh'
          }}
        />
        <Typography variant='body1' component='p' gutterBottom style={styles}>
          Albums
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <img
          src={bouquet}
          alt=''
          style={{
            height: '8vh'
          }}
        />
        <Typography variant='body1' component='p' gutterBottom style={styles}>
          Bouquets
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <img
          src={frame}
          alt=''
          style={{
            height: '8vh'
          }}
        />
        <Typography variant='body1' component='p' gutterBottom style={styles}>
          Frames
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <img
          src={wreath}
          alt=''
          style={{
            height: '8vh'
          }}
        />
        <Typography variant='body1' component='p' gutterBottom style={styles}>
          Wreaths
        </Typography>
      </Grid>
    </Grid>
  );
}
