import React from "react";
import Coverflow from "react-coverflow";

import baby from "static/images/home/coverflow/baby.jpg";
import grd from "static/images/home/coverflow/grd.jpg";
import portrait from "static/images/home/coverflow/portrait.jpg";
import pregnant from "static/images/home/coverflow/pregnant.png";
import wedding from "static/images/home/coverflow/wedding.png";
import woman from "static/images/home/coverflow/woman.jpg";
import grd6 from "static/images/hire/graduation6.jpeg";

import useWindowWidth from "../../../hooks/useWindowWidth";

export default function SectionCoverFlow() {
    const width = useWindowWidth();
    const numbers = 4 > Math.ceil(width / 375)
        ? Math.ceil(width / 375)
        : 4;
    return (
        <div>
            <Coverflow
                width={width * 0.7}
                height={400}
                displayQuantityOfSide={numbers}
                navigation={false}
                enableHeading={true}>

                <img src={grd6} alt="GRADUATION"/>
                <img src={baby} alt="BABY"/>
                <img src={portrait} alt="PORTRAIT"/>
                <img src={wedding} alt="WEDDING"/>
                <img src={pregnant} alt="PREGNANT"/>
                <img src={woman} alt="ENJOY"/>
                <img src={grd} alt="REGALIA"/>
            </Coverflow>
        </div>
    );
}
