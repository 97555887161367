import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import wedding from "static/videos/wedding.mp4";
import useWindowWidth from "hooks/useWindowWidth";
const videoJsOptions = {
  autoplay: true,
  controls: false,
  muted: false,
  sources: [
    {
      src: wedding,
      type: "video/mp4"
    }
  ],
  width: "100%"
};

export default function AutoVideo() {
  const width = useWindowWidth(window.innerWidth);
  const videoNode = useRef(null);

  useEffect(() => {
    const player = videojs(videoNode.current, videoJsOptions, () => {
      console.log("onPlayerReady");
    });
    return () => {
      player && player.dispose();
    };
  }, []);
  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  return (
    <div>
      <div data-vjs-player style={{ width, height: width * 0.5 }}>
        <video ref={videoNode} loop="loop" className="video-js"></video>
      </div>
    </div>
  );
}
