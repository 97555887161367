import { _post, _get } from './index'

//order
export const makeOrder = (data) => {
    let req = {
        data,
        url: 'make_order'
    };
    return _post(req);
};

//get order
export const getOrder = (id) => {
    let req = {
        url: `get_order/${id}`
    };
    return _get(req);
};

//order
export const payOrder = (data) => {
    let req = {
        data,
        url: 'pay_order'
    };
    return _post(req);
};