import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import action from '../../store/action';
import empty from 'static/images/other/empty-cart.png';
import moment from 'moment';

const useStyles = width =>
  makeStyles(theme => ({
    root: {
      width: width * 0.55,
      [theme.breakpoints.down('sm')]: {
        width: width * 0.74
      },
      [theme.breakpoints.down('xs')]: {
        width: width * 0.9
      }
    },
    title: {
      backgroundColor: 'rgba(0,0,0,0.85)',
      color: 'white',
      textAlign: 'center',
      padding: '2% 0',
      width: '100%'
    },
    table: {
      width: width * 0.551,
      [theme.breakpoints.down('sm')]: {
        width: width * 0.741
      },
      [theme.breakpoints.down('xs')]: {
        width: width * 0.901
      }
    },
    tablehead: {
      backgroundColor: 'rgba(0,0,0,0.1)'
    },
    btn: {
      margin: '5% auto auto 10%'
    },

    price: {
      fontWeight: '800',
      color: 'rgba(193,82,72,1)'
    },
    emptybox: {
      margin: '5% 2%',
      padding: '20% 0',
      border: '0.5px solid rgba(0,0,0,0.5)',
      borderRadius: '5px',
      textAlign: 'center'
    },
    image: {
      width: '60%'
    },
    tableCell: {
      border: '1px solid #ddd',
      fontSize: 'calc(8px + 1vmin)',
      padding: 1
    }
  }));

function Shoppingcart({
  listitems,
  remove_from_cart,
  clear_cart,
  windowWidth
}) {
  const classes = useStyles(windowWidth)();
  const small = windowWidth < 500;
  let grandTotal = 0;
  for (let i = 0; i < listitems.length; i++) {
    grandTotal += Number(listitems[i].subtotal);
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant='h4' gutterBottom>
          My Shopping Cart
        </Typography>
      </div>
      {listitems.length !== 0 ? (
        <React.Fragment>
          <Table
            size={small ? 'small' : 'default'}
            padding={small ? 'none' : 'default'}
            className={classes.table}
            aria-label='simple table'
          >
            <TableHead className={classes.tablehead}>
              <TableRow>
                {!small && (
                  <TableCell className={classes.tableCell} align='center'>
                    Type
                  </TableCell>
                )}
                <TableCell className={classes.tableCell} align='center'>
                  Name
                </TableCell>
                <TableCell className={classes.tableCell} align='center'>
                  Price
                </TableCell>
                <TableCell className={classes.tableCell} align='center'>
                  Time
                </TableCell>
                <TableCell className={classes.tableCell} align='center'>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listitems.map(cartitem => (
                <TableRow key={cartitem.index}>
                  {!small && (
                    <TableCell className={classes.tableCell} align='center'>
                      {cartitem.type === 'cloth' ? 'Regalia' : null}{' '}
                      {cartitem.type === 'product' ? 'Item' : null}
                      {cartitem.type === 'photo' ? 'Photography' : null}
                    </TableCell>
                  )}
                  <TableCell className={classes.tableCell} align='center'>
                    {cartitem.service_name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='center'>
                    ${cartitem.subtotal}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='center'>
                    {cartitem.start && small
                      ? moment(new Date(cartitem.start)).format('MM-DD')
                      : moment(new Date(cartitem.start)).format(
                          'YYYY-MM-DD HH:mm'
                        )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='center'>
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        remove_from_cart(cartitem.index);
                      }}
                    >
                      <DeleteIcon color='error' />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>{' '}
          <br />{' '}
          <Grid container spacing={1}>
            <Grid container item xs={2} md={5} spacing={1}></Grid>
            <Grid container item xs={10} md={7} spacing={1}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='center'>
                      <strong>Subtotal :</strong>
                    </TableCell>
                    <TableCell align='center' className={classes.price}>
                      NZD ${(grandTotal * 0.85).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center'>
                      <strong>GST :</strong>
                    </TableCell>
                    <TableCell align='center' className={classes.price}>
                      NZD ${(grandTotal * 0.15).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center'>
                      <strong>Total :</strong>
                    </TableCell>
                    <TableCell align='center' className={classes.price}>
                      NZD ${grandTotal.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    padding: '5% 10%'
                  }}
                >
                  <Fab
                    variant='extended'
                    color='secondary'
                    className={classes.btn}
                    onClick={() => {
                      clear_cart();
                    }}
                  >
                    Clear
                  </Fab>
                </div>
                <Link
                  style={{
                    textAlign: 'center'
                  }}
                  to='/order'
                >
                  <Fab
                    variant='extended'
                    color='primary'
                    className={classes.btn}
                  >
                    Checkout
                  </Fab>
                </Link>
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <div className={classes.emptybox}>
          <img src={empty} alt='' className={classes.image} />
        </div>
      )}
    </div>
  );
}

export default connect(
  state => state.orderlist,
  action.orderlist
)(Shoppingcart);
