import React from 'react';
import {Box, Container, Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';
import logo from 'static/images/home/ccslogo.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles(theme => ({
    footer: {
        height:"182px",
        borderTop: `0.5px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        background: 'black',
    },
    copyright: {
        color: 'white'
    },
    icon:{
        marginTop: theme.spacing(1),
        color: 'white',
        justifyContent:'center',
    },
    
}));

export default function Footer(props) {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
         <Container maxWidth="md" component="footer">
                <Grid container spacing={4} justify="space-evenly">
                    <img
                        src={logo}
                        alt=""
                        style={{
                        height: '100px'
                    }}/>
                   
                </Grid>
                <Grid container className={classes.icon}>        
                  <FacebookIcon style={{margin: '0 1.5%',fontSize:'40'}}/>
                  <TwitterIcon style={{margin: '0 1.5%',fontSize:'40'}}/>
                  <InstagramIcon style={{margin: '0 1.5%',fontSize:'40'}}/>
                  <a href='https://maps.google.com/?cid=4751054923379732082' style={{color:'white',margin: '0 1.5%',}}>
                  <RoomIcon  style={{fontSize:'40'}}/>
                  </a>
                  
                </Grid>
                <Box mt={3}>
                    <Typography variant="body2" align="center" className={classes.copyright}>
                        Copyright © ccs 2019                      
                    </Typography>
                </Box>
            </Container>
        </div>
    );
}