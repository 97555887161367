const cart = 'theShoppingCart';

export function getCart() {
  return localStorage.getItem(cart)
    ? JSON.parse(localStorage.getItem(cart))
    : [];
}

export function addCart(item) {
  const arr = getCart();
  item.index = arr.length > 0 ? arr[arr.length - 1].index + 1 : 0;
  arr.push(item);
  localStorage.setItem(cart, JSON.stringify(arr));
  console.log(item, localStorage.getItem(cart));
  return JSON.parse(localStorage.getItem(cart));
}

export function removeCart(index) {
  const arr = getCart().filter(item => item.index !== index);
  localStorage.setItem(cart, JSON.stringify(arr));
  return JSON.parse(localStorage.getItem(cart));
}

export function clearCart() {
  localStorage.setItem(cart, JSON.stringify([]));
  return JSON.parse(localStorage.getItem(cart));
}
