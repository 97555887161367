import * as TYPES from '../action-type';
import { diffTypes } from '../../utils/utils';

export default function service(
  state = {
    services: {
      cloth: [],
      photo: [],
      product: []
    }
  },
  action
) {
  switch (action.type) {
    case TYPES.GET_SERVICES:
      {
        state = JSON.parse(JSON.stringify(state));
        const { status, data } = action.Data.data;
        if (status === 200) {
          state.services = diffTypes(data);
        }
      }
      break;
    default:
      break;
  }
  return state;
}
