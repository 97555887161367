import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

export default function SectionOrderInfo(props) {

    let grandTotal = 0;
    const order = props.orderInfo;
    const name = order.firstname + ' ' + order.lastname;
    const email = order.email;
    const phone = order.phone;

    for (let i = 0; i < order.details.length; i++) {
        grandTotal += Number((order.details)[i].subtotal);
    }
    
    return (
        <div
            style={{
            margin: '1%'
        }}>
        <Typography variant="h5" gutterBottom>
        Please confirm your information 
      </Typography>

            <List>
                <ListItem>
                    Name : {name}
                </ListItem>
                <ListItem>
                    Email: {email}                 
                </ListItem>
                <ListItem>
                    Phone: {phone}               
                </ListItem>
                <ListItem>
                    Your total cost : $NZD {(grandTotal).toFixed(2)}                  
                </ListItem>
            </List>
        </div>
    )
}