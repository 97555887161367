import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';

import {withRouter} from 'react-router-dom';
import action from '../../../store/action';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {handleBook, handleAddToCart} from '../../../utils/handleBookAndCart';
import Datetime from 'react-datetime';
const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
        overflowX: 'auto',
        margin: '2% auto'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    time: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid primary',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: 600,
        minHeight: 250,
        borderRadius: '15px',
        textAlign: 'center'
    },
    buttons: {
        textAlign: 'center'
    },
    text: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1)
    }
}));

function ClothList({data, history, add_to_cart}) {
    const classes = useStyles();
    const [open,
        setOpen] = useState(false);
    const [oneCloth,
        setoneCloth] = useState(null);
    const [selectedDate,
        setSelectedDate] = useState(moment().add(1, 'days').hours(10).startOf('hour'));

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const handleOpen = item => {
        setoneCloth(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addToCart=() => {
        handleAddToCart(oneCloth, selectedDate, oneDetail => {
            add_to_cart(oneDetail);
            handleClose();
        });
    }

    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Details</TableCell>
                        <TableCell align='center'>Duration</TableCell>
                        <TableCell align='center'>Price</TableCell>
                        <TableCell align='center'>Actions</TableCell>
                        <TableCell align='center'>Pick Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length>0&&data.map((item,index) => (
                        <TableRow key={item.id}>
                            <TableCell component='th' scope='row' align='center'>
                                {item.description}
                            </TableCell>
                            <TableCell align='center'>Four days</TableCell>
                            <TableCell align='center'>NZD ${item.price}</TableCell>
                            <TableCell style={{paddingRight:"16px"}} align='center'>
                                <Button
                                    variant='outlined'
                                    size='small'
                                    color='secondary'
                                    onClick={() => {
                                    handleOpen(item);
                                }}>
                                    Choose this
                                </Button>
                            </TableCell>{
                                index===0&&<TableCell   rowSpan={data.length}  align='center'>
                                <Datetime
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        timeConstraints={{
                                                        hours: {
                                                            min: 8,
                                                            max: 19
                                                        },
                                                        minutes: {
                                                            step: 15
                                                        }
                                                    }}
                                                        inputProps={{
                                                        placeholder: ' Pickup Date&Time '
                                                    }}
                                                        isValidDate={c => {
                                                        return c > moment();
                                                    }}
                                                        input={false}/></TableCell>
                            }
                        </TableRow>
                    ))}
                       
                </TableBody>
            </Table>
         
            {oneCloth && (
                <Modal
                    aria-labelledby='transition-modal-title'
                    aria-describedby='transition-modal-description'
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500
                }}>
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <h5 id='transition-modal-title'>Check details</h5>
                            <Divider variant='middle'/>

                            <div
                                style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <div
                                    style={{
                                    margin: '1% auto',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    {oneCloth.school}{' - '} {oneCloth.degree}{' - '} {oneCloth.major}
                                </div>
                              
                                        <div>
                                        
                                            <div>
                                     
                                            </div>
                                        </div>
                                  
                            </div>
                            <br/>
                            <Divider variant='middle'/>
                            <br/>
                            <div className={classes.buttons}>
                                <Button
                                    onClick={addToCart}
                                    variant='contained'
                                    color='primary'>
                                    Add to Cart
                                </Button>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => {
                                    handleBook(oneCloth, history, selectedDate);
                                }}
                                    style={{
                                    marginLeft: '2%'
                                }}>
                                    Book Now !
                                </Button>
                                <div className={classes.text}>
                                    {'Want to see our graduation photography packages?'}
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            )}
        </Paper>
    );
}

export default compose(withRouter, connect(null, action.orderlist))(ClothList);
