import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons core components
import Card from "components/layout/Card/Card.js";
import Button from '@material-ui/core/Button';

import image1 from "static/images/photography/carousel0.jpg";
import image2 from "static/images/photography/carousel1.jpg";
import image3 from "static/images/photography/carousel2.jpg";
import image4 from "static/images/photography/carousel3.jpg";
import image5 from "static/images/photography/carousel4.jpg";

import LazyLoad from 'react-lazyload';
import loading from 'static/images/other/loading.gif';

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <div className={classes.section}>
            <LazyLoad
                placeholder={< img src = {
                loading
            }
            alt = '' />}>
                <Card carousel>
                    <Carousel {...settings}>
                        <div>
                            <img src={image1} alt="First slide" className="slick-image"/>
                            <div className="slick-caption">
                                <Button className="slick-icons" variant="contained" color='secondary'>
                                    Like our wedding photography, click here and get more info!
                                </Button>
                            </div>
                        </div>
                        <div>
                            <img src={image2} alt="Second slide" className="slick-image"/>
                            <div className="slick-caption">
                                <Button className="slick-icons" variant="contained" color='secondary'>
                                    Like our sightseeing photography, click here and get more info!
                                </Button>
                            </div>
                        </div>
                        <div>
                            <img src={image3} alt="Third slide" className="slick-image"/>
                            <div className="slick-caption">
                                <Button className="slick-icons" variant="contained" color='secondary'>
                                    Like our portrait photography, click here and get more info!
                                </Button>
                            </div>
                        </div>
                        <div>
                            <img src={image4} alt="Third slide" className="slick-image"/>
                            <div className="slick-caption">
                                <Button className="slick-icons" variant="contained" color='secondary'>
                                    Like our wedding photography, click here and get more info!
                                </Button>
                            </div>
                        </div>
                        <div>
                            <img src={image5} alt="Third slide" className="slick-image"/>
                            <div className="slick-caption">
                                <Button className="slick-icons" variant="contained" color='secondary'>
                                    Like our family photography, click here and get more info!
                                </Button>
                            </div>
                        </div>
                    </Carousel>
                </Card>
            </LazyLoad>
        </div>
    );
}
