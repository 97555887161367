import Shoots from './views/shoots/index';
import About from './views/about/index';
import Contact from './views/contact/index';
import Graduation from './views/graduation/index';
import Product from './views/product/index';
import Wedding from './views/wedding/index';
import Home from './views/home/index';
import Order from './views/order/index';
import Payment from './views/payment/index';
import Finish from './views/finish';
const routes = [
  {
    path: '/index',
    name: 'Home',
    component: Home
  },
  {
    path: '/order',
    component: Order
  },
  {
    path: '/graduation',
    name: 'Graduation',
    component: Graduation
  },
  {
    path: '/wedding',
    name: 'Wedding',
    component: Wedding
  },
  {
    path: '/shoots',
    name: 'shoots',
    component: Shoots
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/payment',
    component: Payment
  },
  {
    path: '/finish',
    component: Finish
  }
];

export default routes;
