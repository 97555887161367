import React from 'react';
import {Container} from '@material-ui/core';
import SectionType from './Sections/SectionType';
import SectionBanner from './Sections/SectionBanner';
import {connect} from 'react-redux';

import SectionCarousel from './Sections/SectionCarousel';


function Shoots(props) {

    const {services} = props;

    const { photo } = services;

    return (
        <div>
            <SectionBanner/>
            <Container style={{
                textAlign: "center"
            }}>

                <SectionType photo={photo}/>
            </Container>
            <SectionCarousel/>
        </div>
    )
}

export default connect(state => state.service)(Shoots);