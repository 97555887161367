/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import routes from 'routes';
import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js';
import Container from '@material-ui/core/Container';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import Shoppingcart from 'components/shoppingcart/Shoppingcart';
import useWindowWidth from '../../../hooks/useWindowWidth';

const useStyles = makeStyles(styles);

const useCustomStyles = makeStyles({
  whiteCart: {
    color: 'white'
  },
  blackCart: {
    color: 'black'
  }
});

const StyledBadge1 = withStyles(theme => ({
  badge: {
    right: -3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge);

function HeaderLinks({ listitems }) {
  const [state, setState] = React.useState({ right: false });

  const windowWidth = useWindowWidth();

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({
      ...state,
      [side]: open
    });
  };

  const getQuantity = () => {
    let num = 0;
    for (let i = 0; i < listitems.length; i++) {
      num += listitems[i].quantity;
    }
    return num;
  };

  const classes = useStyles();
  const customClasses = useCustomStyles();

  const sideList = side => (
    <div
      role='presentation'
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <Shoppingcart windowWidth={windowWidth} />
    </div>
  );

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {routes
          .filter(route => route.name)
          .map((route, index) => (
            <Link
              key={index}
              variant='button'
              to={route.path}
              color='transparent'
              className={classes.navLink}
            >
              {route.name}
            </Link>
          ))}
        <IconButton aria-label='cart'>
          <StyledBadge1 badgeContent={getQuantity()} color='secondary'>
            <ShoppingCartIcon
              onClick={toggleDrawer('right', true)}
              className={
                'carticon ' +
                (windowWidth > 959
                  ? customClasses.whiteCart
                  : customClasses.blackCart)
              }
            />
          </StyledBadge1>
        </IconButton>
        <Drawer
          anchor='right'
          open={state.right}
          onClose={toggleDrawer('right', false)}
        >
          {sideList('right')}
        </Drawer>
      </ListItem>
    </List>
  );
}

export default HeaderLinks;
