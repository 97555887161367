export function diffTypes(services) {
  return services.reduce((obj, service) => {
    const { type } = service;
    obj[type] = obj[type] ? [...obj[type], service] : [service];
    return obj;
  }, {});
}

export function findService(services, type, id) {
  const index = services[type].findIndex(i => i.id === Number(id));
  return index === -1 ? {} : services[type][index];
}

export function createMajorList(cloth, school, degree) {
  const majorList = [];
  const mArray = cloth
    .filter(i => i.school === school && i.degree === degree)
    .map(i => i.major);
  const uniqueMajor = [...new Set(mArray)];
  uniqueMajor.forEach(item => {
    majorList.push({ value: item, label: item });
  });
  return majorList;
}
