import React from 'react';

import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/components.js";


import SectionBar from './Sections/SectionBar';
import SectionInfo from './Sections/SectionInfo';
import SectionServices from './Sections/SectionServices';
import SectionContact from './Sections/SectionContact';
import SectionGallery from './Sections/SectionGallery';
import SectionCoverFlow from './Sections/SectionCoverFlow';

const useStyles = makeStyles(styles);

export default function Home() {
    const classes = useStyles();
    return (
        <div>   
            <div className={classNames(classes.main, classes.mainRaised)}>         
                <SectionCoverFlow/>
                <SectionBar/>
                <SectionInfo/>
                <SectionServices/>
                <SectionContact/>
                <SectionGallery/>
            </div>
        
        </div>

    )
}