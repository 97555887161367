import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    maxWidth: 300,
    margin: '1%'
  },
  bottom: {
    justifyContent: 'center'
  }
});

export const Wedding = ({ photo, handleOpen }) => {
  const classes = useStyles();

  const weddings = photo.filter(value => {
    return value.pack === 'wedding';
  });

  return (
    <Grid container>
      {weddings.map(item => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                component='img'
                alt='Contemplative Reptile'
                height='250'
                image={item.pic_url}
                title='Contemplative Reptile'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='h5'>
                  {item.name}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {item.description}
                </Typography>
                <Typography variant='h5' component='h5' color='secondary'>
                  ${item.price}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.bottom}>
              <Button
                size='small'
                variant='outlined'
                color='secondary'
                onClick={() => {
                  handleOpen(item);
                }}
              >
                Choose This
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const Portrait = ({ photo, handleOpen }) => {
  const classes = useStyles();

  const portraits = photo.filter(value => {
    return value.pack === 'portrait';
  });

  return (
    <Grid container>
      {portraits.map(item => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                component='img'
                alt='Contemplative Reptile'
                height='250'
                image={item.pic_url}
                title='Contemplative Reptile'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='h5'>
                  {item.name}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {item.description}
                </Typography>
                <Typography variant='h5' component='h5' color='secondary'>
                  ${item.price}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.bottom}>
              <Button
                size='small'
                variant='outlined'
                color='secondary'
                onClick={() => {
                  handleOpen(item);
                }}
              >
                Choose This
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const RealEstate = ({ photo, handleOpen }) => {
  const classes = useStyles();

  const realestates = photo.filter(value => {
    return value.pack === 'realestate';
  });

  return (
    <Grid container>
      {realestates.map(item => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                component='img'
                alt='Contemplative Reptile'
                height='250'
                image={item.pic_url}
                title='Contemplative Reptile'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='h5'>
                  {item.name}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {item.description}
                </Typography>
                <Typography variant='h5' component='h5' color='secondary'>
                  ${item.price}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.bottom}>
              <Button
                size='small'
                variant='outlined'
                color='secondary'
                onClick={() => {
                  handleOpen(item);
                }}
              >
                Choose This
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const Graduation = ({ photo, handleOpen }) => {
  const classes = useStyles();

  const graduations = photo.filter(value => {
    return value.pack === 'graduation';
  });

  return (
    <Grid container>
      {graduations.map(item => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                component='img'
                alt='Contemplative Reptile'
                height='250'
                image={item.pic_url}
                title='Contemplative Reptile'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='h5'>
                  {item.name}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {item.description}
                </Typography>
                <Typography variant='h5' component='h5' color='secondary'>
                  ${item.price}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.bottom}>
              <Button
                size='small'
                variant='outlined'
                color='secondary'
                onClick={() => {
                  handleOpen(item);
                }}
              >
                Choose This
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const More = ({ photo, handleOpen }) => {
  const classes = useStyles();

  const morephoto = photo.filter(value => {
    return value.pack === 'more';
  });

  return (
    <Grid container>
      {morephoto.map(item => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                component='img'
                alt='Contemplative Reptile'
                height='250'
                image={item.pic_url}
                title='Contemplative Reptile'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='h5'>
                  {item.name}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {item.description}
                </Typography>
                <Typography variant='h5' component='h5' color='secondary'>
                  ${item.price}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.bottom}>
              <Button
                size='small'
                variant='outlined'
                color='secondary'
                onClick={() => {
                  handleOpen(item);
                }}
              >
                Choose This
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
