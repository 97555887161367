import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    totalbox: {
        margin: '1% 25%'
    },
    price: {
        fontWeight: '800',
        color: 'rgba(193,82,72,1)'
    },
});
function OrderDetails({listitems}) {
    const classes = useStyles();

    let grandTotal = 0;
    for (let i = 0; i < listitems.length; i++) {
        grandTotal += Number(listitems[i].subtotal);
    }

    return (
        <div>
            <div>Order details</div>
            <Table aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell align='right'>Service Type</TableCell>
                        <TableCell align='right'>Service Name</TableCell>
                        <TableCell align='right'>Price</TableCell>
                        <TableCell align='right'>Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listitems.map((cartitem, index) => (
                        <TableRow
                            key={cartitem.index
                            ? cartitem.index
                            : index}>
                            <TableCell align='right'>{cartitem.type}</TableCell>
                            <TableCell align='right'>{cartitem.service_name}</TableCell>
                            <TableCell align='right'>${cartitem.subtotal}</TableCell>

                            <TableCell align='right'>
                                {cartitem.start && moment(new Date(cartitem.start)).format('YYYY-MM-DD HH:mm')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={8} sm={8} md={6}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>
                                        <strong>Subtotal :</strong>
                                    </TableCell>
                                    <TableCell align='center' className={classes.price}>
                                        NZD ${(grandTotal * 0.85).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>
                                        <strong>GST :</strong>
                                    </TableCell>
                                    <TableCell align='center' className={classes.price}>
                                        NZD ${(grandTotal * 0.15).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>
                                        <strong>Total :</strong>
                                    </TableCell>
                                    <TableCell align='center' className={classes.price}>
                                        NZD ${grandTotal.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default OrderDetails;
