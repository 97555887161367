import React from 'react';
import Header from './Header/Header';
import HeaderLinks from './Header/HeaderLinks';
import Footer from './footer';
import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Parallax from 'components/layout/Parallax/Parallax';
import logo from 'static/images/home/ccslogo.png';
import AutoVideo from './Media/AutoVideo';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(styles);

function Layout(props) {
  const classes = useStyles();
  const home = props.location.pathname === '/index';
  const wedding = props.location.pathname === '/wedding';

  return (
    <React.Fragment>
      <div className={classes.fixAll}>
        <Header
          brand={
            <Link to='/index' className={classes.navLink}>
              {' '}
              <img
                src={logo}
                alt='Studio Logo'
                style={{
                  height: '7vh'
                }}
              ></img>{' '}
            </Link>
          }
          rightLinks={<HeaderLinks listitems={props.listitems} />}
          fixed
          color='transparent'
          changeColorOnScroll={{
            height: home ? 300 : 50,
            color: 'white'
          }}
          {...props}
        />
        <React.Fragment>
          {home ? (
            <Parallax image={require('static/images/home/home-header-bg.jpg')}>
              <Link
                style={{
                  textAlign: 'center'
                }}
                to='/photography'
              >
                <Button
                  variant='contained'
                  color='default'
                  style={{
                    position: 'absolute',
                    bottom: '20%',
                    right: '20%'
                  }}
                >
                  Start the Journey !
                </Button>
              </Link>
            </Parallax>
          ) : wedding ? (
            <AutoVideo />
          ) : (
            <div
              style={{
                height: '14vh',
                width: '100%',
                background: 'black'
              }}
            ></div>
          )}
        
          {props.children} 
        </React.Fragment>
    
      </div>
      <Footer/>
    </React.Fragment>
  );
}
export default compose(
  withRouter,
  connect(state => state.orderlist)
)(Layout);
