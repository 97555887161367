import React from 'react';
import {Container, Button} from '@material-ui/core';
import {makeOrder} from '../../../api/order';
import {withRouter} from 'react-router-dom';

import {makeStyles} from '@material-ui/core/styles';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import useFormInput from 'hooks/useFormInput';
import {connect} from 'react-redux';
import action from '../../../store/action';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: '2% auto',
        width: '80%'
    },
    button: {
        margin: '5% auto'
    },
    header: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: 'white',
        padding: '5%'

    }
}));

function CreateOrderForm(props) {
    const {listitems, history, global_action_loading, isCart} = props;
    const firstname = useFormInput('');
    const lastname = useFormInput('');
    const phone = useFormInput('');
    const email = useFormInput('');
    const classes = useStyles();

    const submitOrder = () => {
        global_action_loading();

        const newOrder = {
            firstname: firstname.value,
            lastname: lastname.value,
            phone: phone.value,
            email: email.value,
            details: listitems
        };
        makeOrder(newOrder).then(res => {
            if (res.data.status === 200) {
                const state = {
                    ...newOrder,
                    id: res.data.order_id,
                    isCart
                };
                history.push('/payment', state);
            } else {
                alert('failed');
            }
            global_action_loading(false);
        }).catch(err => {
            global_action_loading(false);
            alert('err');
        });
    };
    return (
        <div>
            <div className={classes.header}>
                <Typography variant='body1'>Please fill up to complete the order!</Typography>
            </div>
            <Container>
                <ValidatorForm
                    className={classes.container}
                    onSubmit={submitOrder}
                    onError={errors => console.log(errors)}>
                    <TextValidator
                        className={classes.formControl}
                        label='First Name*'
                        name='First Name'
                        validators={['required']}
                        errorMessages={['First name is required']}
                        {...firstname}/>
                    <TextValidator
                        className={classes.formControl}
                        label='Last Name*'
                        name='Last Name'
                        validators={['required']}
                        errorMessages={['Last name is required']}
                        {...lastname}/>
                    <TextValidator
                        className={classes.formControl}
                        label='Phone*'
                        name='Phone'
                        validators={['required', 'matchRegexp:^[0-9]{1,14}$']}
                        errorMessages={['Phone is required', 'Phone is not valid']}
                        {...phone}/>
                    <TextValidator
                        className={classes.formControl}
                        label='Email*'
                        name='Email'
                        validators={['required', 'isEmail']}
                        errorMessages={['Email is required', 'email is not valid']}
                        {...email}/>
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.button}>
                        Confirm and pay now !
                    </Button>
                </ValidatorForm>
                <Typography variant='caption'>Please feel free to ask questions before order !</Typography>
            </Container>
        </div>

    );
}
export default connect(null, action.globalLoading)(withRouter(CreateOrderForm));
