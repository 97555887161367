import React from 'react';
import Flippy, {FrontSide, BackSide} from 'react-flippy';

import action from '../../../store/action';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import LazyLoad from 'react-lazyload';
import loading from 'static/images/other/loading.gif';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    card: {
        marginTop: '15px'
    }
}));

function ProductCard({product, add_to_cart}) {
    const classes = useStyles();

    const handleAddToCart = item => {
        const oneDetail = {
            service_id: item.id,
            service_name: item.name,
            type: item.type,
            title: item.name,
            quantity: 1,
            description: item.description
        };
        oneDetail.subtotal = (oneDetail.quantity * item.price).toFixed(2);
        add_to_cart(oneDetail);
    };

    return (
        <div className={classes.root}>
            <Grid container>
                {product.map(i => (
                    <Grid item xs={12} sm={6} md={4} lg={3} spacing={3} key={i.id}>
                        <div
                            style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Flippy flipOnHover={true} // default false
                                        flipDirection='horizontal' // horizontal or vertical
                                        flipOnClick={true}>
                                        <FrontSide
                                            style={{
                                            backgroundColor: 'white',
                                            padding: 0,
                                            width: '250px',
                                            height: '250px',
                                            overflow: 'hidden'
                                        }}>
                                        <LazyLoad 
                                        placeholder={<img src={loading} alt=''/>} >
                                            <img src={i.pic_url} alt='' />
                                            </LazyLoad>
                                        </FrontSide>
                                        <Card>
                                            <BackSide
                                                style={{
                                                backgroundColor: 'white',
                                                width: '250px',
                                                height: '250px'
                                            }}>
                                                <CardContent>
                                                    <Typography variant='h5' component='h5'>
                                                        {i.name}
                                                    </Typography>
                                                    <br/>
                                                    <Divider/>
                                                    <br/>
                                                    <Typography variant='body2' component='p' color='textSecondary'>
                                                        Description: {i.description}
                                                    </Typography>
                                                </CardContent>
                                            </BackSide>
                                        </Card>
                                    </Flippy>
                                    <CardActions
                                        disableSpacing
                                        style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        overflowX: 'hidden'
                                    }}>
                                        <span style={{margin:'4%'}}>
                                            <Typography variant='subtitle1' component='p'>
                                                {i.name}
                                            </Typography>
                                        </span>
                                        <span style={{margin:'4%'}}>
                                            <Typography color='error' variant='subtitle1' component='p'>
                                                ${i.price}
                                            </Typography>
                                        </span>
                                        <span>
                                            <Button
                                                style={{
                                                float: 'right',
                                                position: 'relative'
                                            }}>
                                                <AddShoppingCartIcon
                                                    color='primary'
                                                    onClick={() => {
                                                    handleAddToCart(i);
                                                }}></AddShoppingCartIcon>
                                            </Button>
                                        </span>
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default connect(null, action.orderlist)(ProductCard);
