import React, { useEffect } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Layout from './components/layout/index';
import GlobalLoading from './components/globalLoading';
import { Provider, connect } from 'react-redux';
import store from './store';
import { createBrowserHistory } from 'history';
import routes from './routes';
import action from './store/action';

const history = createBrowserHistory();

const App = React.memo(props => {
  const { globalLoading } = props;

  return (
    <React.Fragment>
      <GlobalLoading loading={globalLoading} />
      <Router history={history}>
        <Layout>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                component={route.component}
              />
            ))}
            <Redirect to='/index' />
          </Switch>
        </Layout>
      </Router>
    </React.Fragment>
  );
});

const ConnectAppPage = connect(state => state.globalLoading)(App);

const Root = React.memo(props => {
  const { get_services } = props;
  useEffect(() => {
    get_services();
  }, [get_services]);
  return <ConnectAppPage />;
});

const ConnectRootPage = connect(
  null,
  action.service
)(Root);

export default class extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectRootPage />
      </Provider>
    );
  }
}
