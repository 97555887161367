import React from 'react'

export default function SectionContact() {
    const styles = {
        padding: "5%",
        border: "1px solid gray",
        height: "200px",
        margin: "2% 5%",
        textAlign: "center"
        
    }
    return (
        <div style={styles}>
            <h1>Social media</h1>
        </div>
    )
}
