import React from "react";
import Typography from '@material-ui/core/Typography';

export default function SectionInfo() {

    const styles = {
        border: "1px solid gray",
        padding: "1% 2%",
        margin: "1% 10%",
        textAlign: "center",
        fontFamily:'Optima',

    }

    return (
        <div style={styles}>
            <Typography>
                We offer a wide range of photography services.With HD technology and a fresh concept, Colour City Studio has the talent and
                expertise to capture the magical moments of life. Each client is looking for a
                unique experience, and we aim to discover their preferences from the first
                meeting. Hire us today for your shoot and feel the difference of working with
                true professionals.
            </Typography>
        </div>
    );
}
