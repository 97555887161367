import { useState } from 'react';

const useFormInput = (init, callback) => {
  const [value, setValue] = useState(init);
  const onChange = e => {
    setValue(e.target.value);
  };
  callback && callback();
  return { value, onChange };
};

export default useFormInput;