import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import thanks from 'static/images/other/thanks.png';
import Divider from '@material-ui/core/Divider';
import {getOrder} from '../../api/order';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import action from '../../store/action';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        margin: '5% 5% 0 5%'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    content: {
        textAlign: 'center'
    },
    cover: {
        width:'100%',
        height:'250px'
    }
});
function FinishOrderPage({location, global_action_loading}) {
    const classes = useStyles();

    const order_id = location
        .pathname
        .substring(location.pathname.lastIndexOf('/') + 1);
    const [order,
        setOrder] = useState(null);
    useEffect(() => {
        global_action_loading();
        getOrder(order_id).then(res => {
            if (res.data.status === 200) {
                setOrder(res.data.data);
            } else {
                alert('not get');
            }
            global_action_loading(false);
        }).catch(err => {
            global_action_loading(false);
            alert('err');
        });
    }, [global_action_loading, order_id]);
    return (
       
            <Card className={classes.card}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={6}>
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                <Typography component='h5' variant='h5'>
                                    Your Order Details
                                </Typography>
                                <br/>
                                <Typography variant='subtitle2' color='textSecondary'>
                                    {order
                                        ? (
                                            <React.Fragment>
                                                <div>Email: {order.email}</div>
                                                <div>Phone: {order.phone}</div>
                                                <div>
                                                    Name: {order.firstname}
                                                    {order.lastname}
                                                </div>
                                            </React.Fragment>
                                        )
                                        : (
                                            <div>Waiting</div>
                                        )}
                                </Typography>
                                <br/>
                                <Divider/>
                                <br/>
                                <Typography variant='body1' color='textSecondary'>
                                    We've sent an email to you with your order information, if you still have any
                                    questions, we'll be glad to help with that !
                                </Typography>
                            </CardContent>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={10} md={6}>
                        <CardMedia className={classes.cover} image={thanks}/></Grid>
                </Grid>

            </Card>
       
    );
}

export default connect(null, action.globalLoading)(withRouter(FinishOrderPage));
