import * as TYPES from '../action-type';
import { getServices } from '../../api/service'

const service = {
    get_services(props) {
        return async dispatch => {
            let Data = await getServices(props);
            dispatch({
                type: TYPES.GET_SERVICES,
                Data,
            });
        }
    }
};

export default service;

