import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useFormInput from '../../../hooks/useFormInput';
import GridContainer from 'components/layout/Grid/GridContainer.js';
import GridItem from 'components/layout/Grid/GridItem.js';
import CustomInput from 'components/layout/CustomInput/CustomInput.js';
import Button from 'components/layout/CustomButtons/Button.js';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js';

const useStyles = makeStyles(styles);

export default function SectionMessage() {
  const classes = useStyles();
  const aLink = useRef(null);
  const name = useFormInput('');
  const email = useFormInput('');
  const message = useFormInput('');

  const sendEmail = () => {
    console.log(name.value, email.value, message.value);

    aLink.current.href = `mailto:azimeng520@gmail.com?subject=My Message&body=Hi, I am ${name.value}.
    My email is ${email.value}.
    I want to say: ${message.value}.
    Please email me ASAP. Thank you.`;
    console.log(aLink.current);
    setTimeout(() => {
      aLink.current.click();
    }, 10);
  };

  return (
    <div className={classes.section}>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={12}>
          <h1 className={classes.title}>Contact us</h1>
          <h4 className={classes.description}>
            Please let us know what we could help
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText='Your Name'
                  id='name'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={name}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText='Your Email'
                  id='email'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={email}
                />
              </GridItem>
              <CustomInput
                labelText='Your Message'
                id='message'
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  ...message,
                  multiline: true,
                  rows: 8
                }}
              />
              <GridContainer
                style={{ width: '100%', justifyContent: 'center' }}
              >
                <Button onClick={sendEmail} color='primary'>
                  Send Message
                </Button>
                <a
                  ref={aLink}
                  style={{ visibility: 'hidden' }}
                  target='_blank'
                  href={'mailto:azimeng520@gmail.com?subject=My Message&body=Hi'}
                  rel='noopener noreferrer'
                >
                  {' '}
                </a>
              </GridContainer>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
