import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import useSelect from 'hooks/useSelect/index.js';
import Grid from '@material-ui/core/Grid';
import SelectCategory from './SelectCategory';
import ClothList from './ClothList';
import { createMajorList } from '../../../utils/utils';
import { connect } from 'react-redux';

const schools = [
  {
    value: 'AUT',
    label: 'AUT'
  },
  {
    value: 'UOA',
    label: 'UNIVERSITY OF AUCKLAND'
  },
  {
    value: 'MASSY',
    label: 'MASSEY UNIVERSITY'
  }
];

const degrees = [
  {
    value: 'bachelor',
    label: 'Bachelor'
  },
  {
    value: 'gd&pgd',
    label: 'Graduate Diploma & Post Graduate Diploma'
  },
  {
    value: 'master',
    label: 'Master'
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  invisable: {
    display: 'none'
  },
  button: {
    margin: theme.spacing(1)
  }
}));

function SectionSelect({ services }) {
  const classes = useStyles();
  const { cloth } = services;
  const [clothList, setClothList] = useState([]);
  const school = useSelect('AUT');
  const degree = useSelect('bachelor');
  const majors = createMajorList(cloth, school.value, degree.value);

  const [major, setMajor] = useState('');
  const onChange = event => {
    setMajor(event.target.value);
  };
const sMajors=JSON.stringify(majors)
  useEffect(() => {
    setClothList(
      cloth.filter(
        item =>
          item.school === school.value &&
          item.degree === degree.value &&
          item.major === major
      )
    );
  }, [cloth, school.value, degree.value, major]);

  useEffect(() => {
    setMajor(JSON.parse(sMajors).length > 0 ? JSON.parse(sMajors)[0].value : '');
  }, [school.value, degree.value,sMajors ]);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          <SelectCategory
            label='School'
            selectHook={school}
            menu={classes.menu}
            helperText='Please select a school'
            data={schools}
          />
          <SelectCategory
            label='Degree'
            selectHook={degree}
            menu={classes.menu}
            helperText='Please select a degree'
            data={degrees}
          />
          <SelectCategory
            label='Major'
            selectHook={{ value: major, onChange }}
            menu={classes.menu}
            helperText='Please select a major'
            data={majors}
          />
        </Grid>
      </Grid>
      <ClothList data={clothList} />
    </div>
  );
}

export default connect(state => state.service)(SectionSelect);
