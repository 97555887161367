import {combineReducers} from 'redux';

import service from './service'
import globalLoading from './globalLoading'
import orderlist from './orderlist';


let reducer = combineReducers({service, globalLoading, orderlist});

export default reducer;
