import React from 'react';
import SectionMessage from './Sections/SectionMessage';
import SectionMap from './Sections/SectionMap';
import SectionContact from './Sections/SectionContact';
import Grid from '@material-ui/core/Grid';
import {Container} from '@material-ui/core';

export default function Contact() {

    return (
        <div>
            <Container>
                <Grid container>
                    <Grid container item xs={12} sm={8} md={8}>
                        <SectionMessage/>
                    </Grid>
                    <Grid container item xs={12} sm={4} md={4} style={{background:'rgba(0,0,0,0.08)',color:'rgba(0,0,0,0.4)',padding:'8% 5%'}}>
                        <SectionContact/>
                    </Grid>
                </Grid>
            </Container>
            <SectionMap/>

        </div>

    )
}
