import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/layout/Grid/GridContainer.js';
import GridItem from 'components/layout/Grid/GridItem.js';

import Parallax from 'components/layout/Parallax/Parallax.js';

import logo from 'static/images/home/ccslogo.png';

import styles from 'assets/jss/material-kit-react/views/profilePage.js';

const useStyles = makeStyles(styles);

export default function About() {
  const classes = useStyles();

  return (
    <div style={{ background: 'black', minHeight: '1000px' }}>
      <Parallax
        small
        filter
        image={require('static/images/about/banner.png')}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img
                    src={logo}
                    alt='...'
                    style={{ border: '5px solid white' }}
                  />
                </div>
                <div className={classes.name}>
                  <h1 className={classes.title}>COLOR CITY STUDIO</h1>
                  <h4>PHOTOGRAPHY</h4>
                </div>
              </div>
            </GridItem>
          </GridContainer>

          <div className={classes.description}>
            <p>
              Founded in 1993. Professional Graduation Photography Service and
              Graduation Regalia Hire.
            </p>
            <p>
              When it comes to graduation photography services, you can count on
              Colour City Studio to deliver a beautiful selection of
              high-definition pictures for families to treasure forever. Whether
              you’re looking for a custom-designed photo product, or you want a
              simple collection of portraits, explore the endless possibilities
              of working with us..{' '}
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
