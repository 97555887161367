import { _get } from './index'

//获取产品
export const getServices = (data) => {
  let req = {
    data,
    url: 'services'
  };
  return _get(req);
};








