import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
// @material-ui/icons
//import wedding from "static/images/photography/icon/wedding.png";
import CameraEnhanceRoundedIcon from '@material-ui/icons/CameraEnhanceRounded';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import MonochromePhotosIcon from '@material-ui/icons/MonochromePhotos';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import action from '../../../store/action';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Datetime from 'react-datetime';
// core components
import GridContainer from 'components/layout/Grid/GridContainer.js';
import GridItem from 'components/layout/Grid/GridItem.js';
import NavPills from 'components/layout/NavPills/NavPills.js';

import styles from 'assets/jss/material-kit-react/views/componentsSections/pillsStyle.js';
import { Wedding, Portrait, RealEstate, Graduation, More } from './Photography';
import { handleBook, handleAddToCart } from '../../../utils/handleBookAndCart';

const useStyles = makeStyles(styles);
const useCustomStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    overflowX: 'auto',
    margin: '2% auto'
  },
  table: {
    minWidth: 650
  },
  info: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: 'calc(6px + 1.2vmin)',
    marginBottom: '1vmin'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid primary',
    boxShadow: theme.shadows[5],
    padding: '0 5px 10px 5px',
    maxWidth: 600,
    borderRadius: '15px',
    textAlignLast: 'center'
  },
  buttons: {
    textAlign: 'center'
  },
  text: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1)
  }
}));

function SectionType({ photo, history, add_to_cart }) {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const [open, setOpen] = useState(false);
  const [oneBooking, setOneBooking] = useState({
    name: '',
    pack: '',
    type: '',
    price: 0
  });

  const [selectedDate, setSelectedDate] = useState(
    moment()
      .add(1, 'days')
      .startOf('hour')
  );

  const handleOpen = item => {
    console.log(item);
    setOneBooking(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = date => {
    console.log(date);
    setSelectedDate(date);
  };

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id='navigation-pills'>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPills
                color='success'
                horizontal={{
                  tabsGrid: { xs: 12, sm: 2, md: 2 },
                  contentGrid: { xs: 12, sm: 10, md: 10 }
                }}
                tabs={[
                  {
                    tabButton: 'GRADUATION PHOTOGRAPHY',
                    tabIcon: SchoolIcon,
                    tabContent: (
                      <Graduation photo={photo} handleOpen={handleOpen} />
                    )
                  },
                  {
                    tabButton: 'WEDDING PACKAGES',
                    tabIcon: CameraEnhanceRoundedIcon,
                    tabContent: (
                      <Wedding photo={photo} handleOpen={handleOpen} />
                    )
                  },
                  {
                    tabButton: 'PORTRAIT PACKAGES',
                    tabIcon: GroupIcon,
                    tabContent: (
                      <Portrait photo={photo} handleOpen={handleOpen} />
                    )
                  },
                  {
                    tabButton: 'REAL ESTATE PHOTOGRAPHY',
                    tabIcon: HomeIcon,
                    tabContent: (
                      <RealEstate photo={photo} handleOpen={handleOpen} />
                    )
                  },
                  {
                    tabButton: 'More PHOTOGRAPHY',
                    tabIcon: MonochromePhotosIcon,
                    tabContent: <More photo={photo} handleOpen={handleOpen} />
                  }
                ]}
              />
            </GridItem>
            <Modal
              aria-labelledby='transition-modal-title'
              aria-describedby='transition-modal-description'
              className={customClasses.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={open}>
                <div className={customClasses.paper}>
                  <h3
                    style={{ fontSize: 'calc(8px + 2vmin)' }}
                    id='transition-modal-title'
                  >
                    Check details and choose date
                  </h3>
                  <Divider variant='middle' />
                  <div className={customClasses.info}>
                    {' '}
                    <div>Photography : {oneBooking.name}</div>
                    <div
                      style={{
                        textTransform: 'capitalize'
                      }}
                    >
                      details : {oneBooking.description}
                    </div>
                    <div
                      style={{
                        textTransform: 'capitalize'
                      }}
                    >
                      Price : $NZD {oneBooking.price}
                    </div>
                    <div>Pickup a Service Date :</div>
                    <div
                      style={{
                        border: '0.5px solid purple',
                        padding: '3%',
                        borderRadius: '15px'
                      }}
                    >
                      <Datetime
                        variant='outlined'
                        value={selectedDate}
                        onChange={handleDateChange}
                        timeConstraints={{
                          hours: { min: 8, max: 19 },
                          minutes: { step: 15 }
                        }}
                        inputProps={null}
                        isValidDate={c => {
                          return c > moment();
                        }}
                        input={false}
                      />
                    </div>
                  </div>

                  <Divider variant='middle' />
                  <div
                    style={{
                      fontSize: 'calc(5px + 1vmin)',
                      textAlign: 'center'
                    }}
                  >
                    *For better service, we will confirm the service with you
                    after your booking*{' '}
                  </div>

                  <div className={customClasses.buttons}>
                    <Button
                      onClick={() => {
                        handleAddToCart(oneBooking, selectedDate, oneDetail => {
                          add_to_cart(oneDetail);
                          handleClose();
                        });
                      }}
                      style={{
                        fontSize: 'calc(6px + 1.3vmin)',
                        padding: '2px 10px',
                        margin: '6px'
                      }}
                      variant='contained'
                      color='primary'
                    >
                      Add to Cart
                    </Button>
                    <Button
                      style={{
                        fontSize: 'calc(6px + 1.3vmin)',
                        padding: '2px 10px',
                        margin: '6px'
                      }}
                      variant='contained'
                      color='secondary'
                      onClick={() => {
                        handleBook(oneBooking, history, selectedDate);
                      }}
                    >
                      Book Now !
                    </Button>
                  </div>
                </div>
              </Fade>
            </Modal>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withRouter,
  connect(null, action.orderlist)
)(SectionType);
