import moment from 'moment';

export function handleBook(item, history, selectedDate) {
  const oneDetail = createOneDetail(item, selectedDate);
  const details = [];
  details.push(oneDetail);
  const state = {
    details
  };
  history.push('/order', state);
}

export const handleAddToCart = (item, selectedDate, callback) => {
  const oneDetail = createOneDetail(item, selectedDate);
  callback && callback(oneDetail);
};

const createOneDetail = (item, selectedDate) => {
  const start = moment.isMoment(selectedDate)
    ? selectedDate.valueOf()
    : selectedDate.getTime();
  const oneDetail = {
    service_id: item.id,
    service_name: item.name,
    quantity: 1,
    type: item.type,
    start
  };
  if (item.type === 'cloth') {
    oneDetail.title = item.unique;
    oneDetail.end = oneDetail.start + 900000;
  } else {
    oneDetail.title = item.name;
    oneDetail.end = oneDetail.start + 7200000;
  }
  oneDetail.subtotal = (oneDetail.quantity * item.price).toFixed(2);
  return oneDetail;
};
