import React from 'react';
import wechatcode from 'static/images/other/wechatcode.png';
import { Divider,Typography } from '@material-ui/core';

export default function SectionContact() {
  return (
    <div>
      <Typography
        variant='body1'
      >
        Address: <br />
        228 Queen Street, <br />
        Auckland, <br />
        New Zealand <br />
        <br />
        E: info@colorcictystudio.com
        <br />
        P: 09 1234567
        <br />
      </Typography>
      <br />
      <Divider/>
      <br />
      <Typography variant='body2'>
       Want wechat us? Just scan the QR below !
      </Typography>
      <br />
      <img
        src={wechatcode}
        alt=''
        style={{
          height: '25vh',
        }}
      />
    </div>
  );
}
