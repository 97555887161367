import React from 'react';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import ProductCard from './Sections/ProductCard';
import Banner from './Sections/Banner';



function Product(props) {
  const { services } = props;
  const { product } = services;
  return (
    <div style={{marginBottom:'5vh'}}>
      <Container
        style={{
          textAlign: 'center'
        }}
      >
        <Banner />        
        <ProductCard product={product}/>
      </Container>
    </div>
  );
}

export default connect(state => state.service)(Product);
