import React, {useState, useEffect} from 'react';
import CreateOrderForm from './Order/CreateOrderForm';
import OrderDetails from './Order/OrderDetails';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';


const useStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
        overflowX: 'auto',
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    card: {
        display: 'flex'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    content: {
        textAlign: 'center'
    },
    cover: {
        minWidth: 600,
        height: 360
    }
}));

function OrderPage({listitems, location}) {
    const [orders,
        setOrders] = useState(null);
    useEffect(() => {
        location.state
            ? setOrders(location.state.details)
            : setOrders(listitems);
    }, [listitems, location.state]);

    const classes = useStyles();

    return (
        <Container>          
            <div style={{
                textAlign: 'center'
            }}>
                <h2>Complete an Order</h2>
            </div>

            {orders
                ? (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Paper className={classes.paper}>
                                <OrderDetails listitems={orders}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Paper className={classes.paper}>
                                <CreateOrderForm listitems={orders} isCart={!location.state}/>
                            </Paper>
                        </Grid>{' '}
                    </Grid>
                )
                : null}
        </Container>
    );
}
export default connect(state => state.orderlist)(OrderPage);
