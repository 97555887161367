import React from 'react';
import {Typography} from '@material-ui/core';

export default function SectionBanner() {

    const styles = {
        background: "rgba(42,42,43,1)",
        height: "8vh",
        display:' flex',
        alignItems: 'center',
        justifyContent:'center',

    }
    return (
        <div style={styles}>
            <Typography
                style={{
                color: 'white',
                fontSize: '2.5vw',
                fontFamily:'Optima'
            }}
                component='p'
                >WEDDING - PORTRAIT - GRADUATION - COMMERCIAL - REALESTATE & MORE</Typography>
        </div>
    )
}
