import React from 'react';
import GoogleMapReact from 'google-map-react';
import logo from "static/images/home/ccslogo.png";


const AnyReactComponent = () => <div><img src={logo} alt="" style={{height:'50px'}}/>
<h4>228 Queen Street</h4></div>;

export default function SectionMap() {

    const defaultProps = {
        center: {
          lat: -36.85,
          lng: 174.76
        },
        zoom: 15
      };
    return (
        <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCkMnLmj4gh2yoBthJgp53F0TjZfM2H5e4'}}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={-36.8494587}
            lng={174.763117}
          />
        </GoogleMapReact>
      </div>
    )
}
