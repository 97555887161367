import * as TYPES from '../action-type';
import { getCart } from '../../utils/shoppingCart';

export default function orderlist(
  state = {
    listitems: getCart()
  },
  action
) {
  switch (action.type) {
    case TYPES.ADD_TO_CART:
      state = JSON.parse(JSON.stringify(state));
      state.listitems = action.data;
      break;
    case TYPES.REMOVE_FROM_CART:
      state = JSON.parse(JSON.stringify(state));
      state.listitems = action.data;
      break;
    case TYPES.CLEAR_CART:
      state = JSON.parse(JSON.stringify(state));
      state.listitems = action.data;
      break;
    default:
      break;
  }
  return state;
}
