import React from "react";
import Typography from '@material-ui/core/Typography';

export default function SectionBar() {

    const styles = {
        fontFamily: 'Impact',
        fontStyle: 'fantasy',
        paddingTop: '0.5%',
        marginBottom:'0.5%'

    }

    return (
        <div style={{background: 'rgba(50,50,50,0.95)', textAlign: 'center', color: 'white',}}>
            <Typography style={styles} variant='h2'>
                Color City Studio
            </Typography>
            <Typography variant='subtitle1'>
                 Founded in 1993. Professional Graduation Photography Service and Graduation
                Regalia Hire
            </Typography>

        </div>
    );
}
