import React from 'react'
import { MenuItem, TextField ,Grid} from '@material-ui/core/';

export default function FormRow({label,selectHook,menu,helperText,data}) {
    return (
     
            <Grid item sm={4} xs={12} >
                <TextField
                    variant="outlined"
                    select
                    label={label}
                    {...selectHook}
                    SelectProps={{
                    MenuProps: {
                        className:menu
                    }
                }}
                    helperText={helperText}
                    margin="normal">
                    {data.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
    );
}