import React from 'react';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Input from '@material-ui/core/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import alipay from 'static/images/other/alipay.png';
import wechat from 'static/images/other/wechat.png';
import visa from 'static/images/other/visa.png';
import Button from '@material-ui/core/Button';
import useFormInput from 'hooks/useFormInput';
import {payOrder} from '../../../api/order';
import {connect} from 'react-redux';
import action from '../../../store/action';
import {withRouter} from 'react-router-dom';

function SectionPayment(props) {
    const {orderInfo: order, global_action_loading, history, clear_cart} = props;
    const money = useFormInput('');

    const [value,
        setValue] = React.useState(' ');

    const handleChange = event => {
        setValue(event.target.value);
    };

    const payTheOrder = () => {
        global_action_loading();
        payOrder({
            money: Number(money.value),
            order_id: order.id
        }).then(res => {
            if (res.data.status === 200) {
                if (order.isCart) {
                    clear_cart();
                }
                history.push(`/finish/${order.id}`);
            } else {
                alert('Pay Failed');
                global_action_loading(false);
            }
        }).catch(err => {
            alert('Pay ERR');
            global_action_loading(false);
        });
    };

    return (
        <div>
            <Typography variant='h5' component='h5'>
                Choose your payment method
            </Typography>
            <div
                style={{
                marginTop:'4%'
            }}>
                <FormControl component='fieldset'>
                    <FormLabel component='legend'>Payment</FormLabel>
                    <RadioGroup
                        aria-label='payment'
                        name='payment'
                        value={value}
                        onChange={handleChange}>
                        <FormControlLabel
                            value='alipay'
                            control={< Radio />}
                            label={< img alt = 'alipay' src = {
                            alipay
                        } />}/>
                        <FormControlLabel
                            value='wechat'
                            control={< Radio />}
                            label={< img alt = 'wechat' src = {
                            wechat
                        } />}/>
                        <FormControlLabel
                            value='visa'
                            control={< Radio />}
                            label={< img alt = 'visa' src = {
                            visa
                        } />}/>
                    </RadioGroup>
                </FormControl>
                </div>
                <br/>
                <Input id='component-simple' {...money} placeholder='Please Input money'/>{' '}
                <br/>
                <span style={{
                    color: 'red'
                }}>
                    Input money should equal the total cost
                </span>
                <br/>
                <Button onClick={payTheOrder} variant='outlined' color='primary'>
                    Next
                </Button>
            
        </div>
    );
}

export default connect(null, {
    ...action.globalLoading,
    ...action.orderlist
})(withRouter(SectionPayment));
