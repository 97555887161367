import React from 'react';

import {makeStyles} from "@material-ui/core/styles";

import classNames from "classnames";

import GridContainer from "components/layout/Grid/GridContainer.js";
import GridItem from "components/layout/Grid/GridItem.js";
import NavPills from "components/layout/NavPills/NavPills.js";

import PhotoCamera from "@material-ui/icons/PhotoCamera";
import School from "@material-ui/icons/School";
import Favorite from "@material-ui/icons/Favorite";

import p1 from 'static/images/home/p1.png';
import p2 from 'static/images/home/p2.png';
import p3 from 'static/images/home/p3.png';
import p4 from 'static/images/home/p4.png';

import r1 from 'static/images/home/r1.png';
import r2 from 'static/images/home/r2.png';
import r3 from 'static/images/home/r3.png';
import r4 from 'static/images/home/r4.png';
import r5 from 'static/images/home/r5.png';

import s1 from 'static/images/home/s1.png';
import s2 from 'static/images/home/s2.png';
import s3 from 'static/images/home/s3.png';
import s4 from 'static/images/home/s4.png';
import s5 from 'static/images/home/s5.png';

import LazyLoad from 'react-lazyload';
import loading from 'static/images/other/loading.gif';

import styles from 'assets/jss/material-kit-react/views/profilePage.js';

const useStyles = makeStyles(styles);
export default function SectionServices(props) {
    const classes = useStyles();
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
        <div>
            <div className={classes.divider}>
                <span>Our Services</span>
            </div>
            <GridContainer justify="center">
                <LazyLoad
                    placeholder={< img src = {
                    loading
                }
                alt = '' />}>
                    <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                        <NavPills
                            alignCenter
                            color="primary"
                            tabs={[
                            {
                                tabButton: "Photography",
                                tabIcon: PhotoCamera,
                                tabContent: (
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={4}>
                                            <img alt="..." src={p1} className={navImageClasses}/>
                                            <img alt="..." src={p2} className={navImageClasses}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <img alt="..." src={p3} className={navImageClasses}/>
                                            <img alt="..." src={p4} className={navImageClasses}/>
                                        </GridItem>
                                    </GridContainer>
                                )
                            }, {
                                tabButton: "Regalia Hire",
                                tabIcon: School,
                                tabContent: (
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={4}>
                                            <img alt="..." src={r1} className={navImageClasses}/>
                                            <img alt="..." src={r2} className={navImageClasses}/>
                                            <img alt="..." src={r3} className={navImageClasses}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <img alt="..." src={r4} className={navImageClasses}/>
                                            <img alt="..." src={r5} className={navImageClasses}/>
                                        </GridItem>
                                    </GridContainer>
                                )
                            }, {
                                tabButton: "Products",
                                tabIcon: Favorite,
                                tabContent: (
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={4}>
                                            <img alt="..." src={s1} className={navImageClasses}/>
                                            <img alt="..." src={s2} className={navImageClasses}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <img alt="..." src={s3} className={navImageClasses}/>
                                            <img alt="..." src={s4} className={navImageClasses}/>
                                            <img alt="..." src={s5} className={navImageClasses}/>
                                        </GridItem>
                                    </GridContainer>
                                )
                            }
                        ]}/>
                    </GridItem>
                </LazyLoad>
            </GridContainer>
        </div>
    )
}
